import cn from 'clsx';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import { resolveUrl } from '../../../utils/string';
import Image from '../Image';
import ProductIndexInfo from '../ProductIndexInfo';

function ProductLinkImage({ index, isSmall, item, pictures, total }) {
    const getPicture = (elem, pictureslist) => {
        const img = pictureslist.filter(
            (picture) => picture.name === elem.image,
        )[0];

        return img.childImageSharp.fixed;
    };

    return (
        <Link
            to={resolveUrl(`/cuisines/${item.slug}`)}
            className={cn(isSmall ? 'grid-item' : 'full')}
        >
            <Image fixed={getPicture(item, pictures)} alt={item.title} />
            <ProductIndexInfo
                index={index + 1}
                data={item}
                total={total}
                isSmall={isSmall}
            />
        </Link>
    );
}

ProductLinkImage.defaultProps = {
    isSmall: false,
};

ProductLinkImage.propTypes = {
    index: PropTypes.number.isRequired,
    isSmall: PropTypes.bool,
    item: PropTypes.object.isRequired,
    pictures: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
};

export default ProductLinkImage;
