import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';

import './ProductIndexInfo.styl';

const ProductIndexInfo = ({ data, index, isSmall, total }) => (
    <div className={clsx('product-position-info', { small: isSmall })}>
        <div className='slider-position'>
            <span className='index'>
                {total >= 10 && index < 10 ? 0 : ''}
                {index}
            </span>
            <span className='sep' />
            <span className='total'>{total}</span>
        </div>
        <div className='title'>{data.title}</div>
        <div className='description'>{data.subtitle}</div>
    </div>
);
ProductIndexInfo.defaultProps = {
    isSmall: false,
};

ProductIndexInfo.propTypes = {
    data: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isSmall: PropTypes.bool,
    total: PropTypes.number.isRequired,
};

export default ProductIndexInfo;
