import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import SimpleSlider from '../views/components/Slider';
import MainLayout from '../views/layouts/MainLayout';
import { mapOrder } from '../utils/array';

const IndexPage = ({ data }) => {
    const { kitchens, pictures } = data;

    const list = kitchens.edges.map((n) => n.node);
    const picturesList = pictures.edges.map((n) => n.node);

    const order = [
        'balsamita',
        'chia',
        'stevia',
        'alpinia',
        'artemisia',
        'garcinia',
        'pachira',
        'pasilla',
        'verbena',
    ];
    const sortedList = mapOrder(list, order, 'slug');

    return (
        <MainLayout transparent>
            <div
                className='home-slider'
                style={{ maxHeight: '100vh', overflow: 'hidden' }}
            >
                <SimpleSlider list={sortedList} pictures={picturesList} />
            </div>
        </MainLayout>
    );
};

IndexPage.propTypes = {
    data: PropTypes.object.isRequired,
};

export default IndexPage;

export const query = graphql`
    query {
        pictures: allFile(filter: { sourceInstanceName: { eq: "kitchens" } }) {
            edges {
                node {
                    ...ImageFullScreen
                }
            }
        }
        kitchens: allKitchensCsv {
            edges {
                node {
                    ...KitchenSlide
                }
            }
        }
    }
`;
