/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Slider from 'react-slick';

import './Slider.styl';
import ProductLinkImage from '../ProductLinkImage';

const SimpleSlider = ({ list, pictures }) => {
    const ArrowNext = ({ onClick, ...props }) => (
        <button className='chevron next' onClick={onClick} type='button'>
            <i className='icon icon-chevron-right' />
        </button>
    );
    const ArrowPrev = ({ onClick, ...props }) => (
        <button className='chevron prev' onClick={onClick} type='button'>
            <i className='icon icon-chevron-left' />
        </button>
    );

    const settings = {
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        infinite: true,
        nextArrow: <ArrowNext />,
        prevArrow: <ArrowPrev />,
        slidesToScroll: 1,
        slidesToShow: 1,
        // speed: 0,
        speed: 1500,
    };

    return (
        <Slider {...settings} style={{ height: '100%' }}>
            {list.map((item, i) => (
                <ProductLinkImage
                    // eslint-disable-next-line react/no-array-index-key
                    key={`home-slider-data-${i}`}
                    index={i}
                    item={item}
                    pictures={pictures}
                    total={list.length}
                />
            ))}
        </Slider>
    );
};

SimpleSlider.propTypes = {
    list: PropTypes.array.isRequired,
    pictures: PropTypes.array.isRequired,
};

export default SimpleSlider;
