const trimUrl = (path) => path.replace(/\s/g, '');

const resolveUrl = (...paths) => {
    const url = paths.reduce((resolvedUrl, path) => {
        const urlPath = path.toString().trim();
        if (urlPath) {
            // eslint-disable-next-line no-param-reassign
            resolvedUrl +=
                (resolvedUrl === '' ? '' : '/') +
                urlPath.replace(/^\/|\/$/g, '');
        }
        return resolvedUrl;
    }, '');

    return url === '' ? '/' : `/${trimUrl(url)}/`;
};

module.exports = { resolveUrl, trimUrl };
