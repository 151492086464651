// eslint-disable-next-line import/prefer-default-export
export function mapOrder(array, order, key) {
    array.sort((a, b) => {
        const A = a[key];
        const B = b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        }
        return -1;
    });

    return array;
}
